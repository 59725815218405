/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Production steps of ECMA-262, Edition 6, 22.1.2.1
            if (!Array.from) {
              Array.from = (function () {
                var toStr = Object.prototype.toString;
                var isCallable = function (fn) {
                  return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
                };
                var toInteger = function (value) {
                  var number = Number(value);
                  if (isNaN(number)) { return 0; }
                  if (number === 0 || !isFinite(number)) { return number; }
                  return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
                };
                var maxSafeInteger = Math.pow(2, 53) - 1;
                var toLength = function (value) {
                  var len = toInteger(value);
                  return Math.min(Math.max(len, 0), maxSafeInteger);
                };

                // The length property of the from method is 1.
                return function from(arrayLike/*, mapFn, thisArg */) {
                  // 1. Let C be the this value.
                  var C = this;

                  // 2. Let items be ToObject(arrayLike).
                  var items = Object(arrayLike);

                  // 3. ReturnIfAbrupt(items).
                  if (arrayLike == null) {
                    throw new TypeError("Array.from requires an array-like object - not null or undefined");
                  }

                  // 4. If mapfn is undefined, then let mapping be false.
                  var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                  var T;
                  if (typeof mapFn !== 'undefined') {
                    // 5. else
                    // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                    if (!isCallable(mapFn)) {
                      throw new TypeError('Array.from: when provided, the second argument must be a function');
                    }

                    // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                    if (arguments.length > 2) {
                      T = arguments[2];
                    }
                  }

                  // 10. Let lenValue be Get(items, "length").
                  // 11. Let len be ToLength(lenValue).
                  var len = toLength(items.length);

                  // 13. If IsConstructor(C) is true, then
                  // 13. a. Let A be the result of calling the [[Construct]] internal method
                  // of C with an argument list containing the single item len.
                  // 14. a. Else, Let A be ArrayCreate(len).
                  var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                  // 16. Let k be 0.
                  var k = 0;
                  // 17. Repeat, while k < len… (also steps a - h)
                  var kValue;
                  while (k < len) {
                    kValue = items[k];
                    if (mapFn) {
                      A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                    } else {
                      A[k] = kValue;
                    }
                    k += 1;
                  }
                  // 18. Let putStatus be Put(A, "length", len, true).
                  A.length = len;
                  // 20. Return A.
                  return A;
                };
              }());
            }
        // JavaScript to be fired on all pages
        $('*[data-toggle="tooltip"]').tooltip();

        //alert user to staging Environment
        /*var pathname = window.location.href;
        var staging = 'nerdymind';
        var info = localStorage.getItem('staging') || '';
        if(pathname.indexOf(staging) && info === ''){
          console.info("You are viewing the Staging Environment.");
          var redirect = confirm("You are viewing the Staging do you wish to go to the live site?");
          if(redirect === true){
            window.location.replace("http://hmre.net/");
          }
          localStorage.setItem('staging', '1');
        }*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $("[id*='featured-image']").owlCarousel({
            loop:false,
            center: true,
            items:1,
            autoplay: true,
            autoplaySpeed: 500,
            autoplayTimeout: 15000,
            fluidSpeed: 500,
            smartSpeed: 500,
            margin:10,
            nav:true,
            navText:["<i class='arrow-left'></i>","<i class='arrow-right'></i>"],
        });

        // Typewriter functionlaity
        // https://github.com/mattboldt/typed.js/
        var typed = [];
        $('#typed-strings').children().each(function() {
            typed.push(this.innerHTML);
        });
        $(".typewriter").typed({
          strings: typed,
          typeSpeed: 35,
          startDelay: 500,
          backSpeed: 15,
          backDelay: 1200,
          showCursor: true,
          loop: true,
        });

        //active page reset
        $( "header.banner.navbar .active" ).last().addClass('active-id');
        $( "header.banner.navbar .active" ).removeClass('active');
        $('.active-id').addClass('active');
        $('.active-id').removeClass('active-id');

        //testimonial hide show
        //const testimonialBox = document.querySelectorAll('.testimonial');
        var view = document.querySelectorAll('.testimonial-wrapper');
        var testimonial = document.querySelectorAll('.testimonial');
        var active = true;

        function toggleTestimonial(e) {
            e.target.offsetParent.classList.toggle('active');
        }
        function hideViewMore(e) {
            if (e.offsetHeight < '130') {
                e.offsetParent.classList.add('small-box');
            }
        }
        Array.from(view).forEach(function (e) {
            e.addEventListener('click', toggleTestimonial);
        });
        Array.from(testimonial).forEach(function (e) {
          hideViewMore(e);
        });




      }
    },
    // Home page
    'home': {
      init: function() {

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
